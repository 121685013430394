import { useEffect } from "react";
import { useWatch } from "react-hook-form";

import { Routes } from "~/constants/routes";
import { useDebounce } from "~/hooks/use-debounce";
import { useWtFetcher } from "~/hooks/use-wt-fetcher";

import type { HandleStatus } from "../types";

export function useValidateHandleInput(
  form: any,
  allowedHandle?: string,
  shouldValidate?: boolean,
) {
  const handle = useWatch({ name: "handle", control: form.control });
  const {
    clearErrors,
    formState: { errors },
  } = form;
  const debouncedHandle = useDebounce(handle, 250);

  const [{ load, data }, validateState] = useWtFetcher<any>({
    onError: (response) => form.setError("handle", { type: "custom", message: response.error }),
  });

  // run handle validation whenever input changes with a slight debounce

  useEffect(() => {
    if (!shouldValidate) return;
    if (!debouncedHandle) return;
    if (errors.handle && errors.handle.type !== "custom") return;
    if (allowedHandle && debouncedHandle === allowedHandle) return;
    clearErrors("handle");
    load(Routes.actions.validateUsername({ username: debouncedHandle }));
  }, [allowedHandle, clearErrors, debouncedHandle, errors, shouldValidate, load]);

  function getHandleStatus(): HandleStatus {
    if (!handle) return "initial";
    if (handle === allowedHandle) return "initial";
    if (validateState.isFetching) return "loading";
    if (errors.handle) return "unavailable";
    if (validateState.isError) return "unavailable";
    if (validateState.isSuccess && handle.trim() === data?.data?.username?.trim())
      return "available";
    if (validateState.isSuccess && handle.trim() !== data?.data?.username.trim())
      return "unavailable";
    return "initial";
  }

  return { validateState, getHandleStatus };
}

import type { Auth } from "~/types";

export function getOnboardingStatus(auth: Auth) {
  const isGifter = auth.gifter && !auth.gifter.handle;
  const isWisher = auth.user && !auth.gifter && !auth.wisher?.handle;
  return {
    isCompleted: !isGifter && !isWisher,
    isGifter,
    isWisher,
  };
}

import { Link, Typography } from "@mui/material";

import { Routes } from "~/constants/routes";
import { useWtFetcher } from "~/hooks/use-wt-fetcher";

export function ResendConfirmationEmailButton({ email }: { email: string }) {
  const [submit, submitState] = useWtFetcher({
    onSuccess: () => alert("Email sent!"),
    onError: (response) => alert(response.error),
  });
  return (
    <Typography
      method="POST"
      component={submit.Form}
      action={Routes.actions.auth.resendConfirmation()}
      color="gray.5"
    >
      <input type="hidden" name="email" value={email} />
      <Typography>Don't see our email? </Typography>
      <Link
        component="button"
        type="submit"
        color={submitState.isFetching ? "inherit" : undefined}
        disabled={submitState.isFetching}
      >
        {submitState.isFetching ? <span>Resending...</span> : <span>Resend link.</span>}
      </Link>
      {!submitState.isFetching && submitState.error && (
        <Typography component="p" color="red.5">
          {submitState.error}
        </Typography>
      )}
    </Typography>
  );
}

import { Box, Typography } from "@mui/material";

export function RequestResetPasswordSuccess({ email }: { email: string }) {
  return (
    <Box sx={{ textAlign: "center" }}>
      <img
        style={{
          position: "relative",
          height: "140px",
        }}
        alt="mail graphic"
        src="/images/mail_graphic.png"
      ></img>
      <Box mt={2} />
      <Typography variant="h7" component="h4">
        Check Your Email
      </Typography>
      <Box mt={2} />
      <Typography variant="b4" component="p">
        Password reset instructions have been sent to your email
      </Typography>
      <Box mt={2} />
      <Typography variant="sh5" component="p">
        {email}
      </Typography>
      <Box mt={2} />
      <Typography variant="b4" component="p">
        Check your email and click the reset link to continue.
      </Typography>
    </Box>
  );
}

import { Box, Button, Typography } from "@mui/material";
import { Link } from "@remix-run/react";

import { Routes } from "~/constants/routes";
import { useWtFetcher } from "~/hooks/use-wt-fetcher";

export function ResetPasswordTokenExpired({ email }: { email: string }) {
  const [submit, submitState] = useWtFetcher({
    onError: (response) => {
      alert(response.error);
    },
  });

  return (
    <Box
      component={submit.Form}
      method="post"
      action={Routes.auth.forgotPassword()}
      sx={{ textAlign: "center" }}
    >
      <input type="hidden" name="email" value={email} />
      <Typography variant="h7" component="h4">
        Token expired
      </Typography>
      <Box mt={2} />
      <Typography component="p" variant="b4">
        The email token expired. <br />
        Resend email link to <b>{email}</b>?
      </Typography>
      <Box mt={2} />
      <Button component={Link} variant="outlined" to={Routes.auth.forgotPassword()} sx={{ mr: 2 }}>
        Change email
      </Button>
      <Button
        type="submit"
        variant="contained"
        color={submitState.isError ? "error" : "primary"}
        disabled={submitState.isFetching}
      >
        {submitState.isFetching ? <span>Resending...</span> : <span>Resend</span>}
      </Button>
    </Box>
  );
}

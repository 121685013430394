import { z } from "zod";

const EmailSchema = z
  .string()
  .trim()
  .nonempty("Email is required.")
  .email("Enter a valid e-mail address");

const PasswordSchema = z
  .string()
  .trim()
  .nonempty("Password is required.")
  .min(8, "Password must be at least 8 characters.");

export const LoginSchema = z.object({
  email: EmailSchema,
  password: z.string().nonempty("Password is required."),
  masterKey: z.string().optional(),
});

export const SignUpSchema = z.object({
  role: z.enum(["wisher", "gifter"]),
  email: EmailSchema,
  password: PasswordSchema,
  agreedToTerms: z
    .preprocess((v) => v === true || v === "true", z.boolean())
    .refine((v) => v, { message: "You must accept the Terms to continue." }),
});

export const ForgotPasswordSchema = z.object({
  email: EmailSchema,
});

export const ResetPasswordSchema = z
  .object({
    email: EmailSchema,
    token: z.string().nonempty("Token is required."),
    password: PasswordSchema,
    passwordConfirm: z.string(),
  })
  .refine((data) => data.password === data.passwordConfirm, {
    message: "Passwords do not match.",
    path: ["passwordConfirm"],
  });
